import { useRoles } from '../../provider/RoleProvider'
import { BusinessUnit, ProductionOrderDto, ProductionSite } from './order'
import { RoleRights, Roles } from '../../provider/roles'

export const hasOrderRightAssigned = (order: ProductionOrderDto | undefined, roleRight: RoleRights): boolean | undefined => {
  const roles = useRoles()
  const productionSite = order?.productionSite
  const businessUnit = order?.businessUnit
  return roles?.[`${businessUnit}.${productionSite}.${roleRight}` as Roles]
}

export const isOrderReadOnly = (order: ProductionOrderDto): boolean => {
  return (
    !hasOrderRightAssigned(order, RoleRights.EDIT)
    || (order?.gxp && !hasOrderRightAssigned(order, RoleRights.GXP_EDIT))
  ) ?? true
}

export const hasRightAssigned = (businessUnits: BusinessUnit[], role: RoleRights): boolean | undefined => {
  const roles = useRoles()

  const hasSiteRightAssigned = (site: ProductionSite) => {
    return businessUnits.some(businessUnit => roles?.[`${businessUnit}.${site}.${role}` as Roles])
  }

  return Object.values(ProductionSite)
    .some(hasSiteRightAssigned)
}


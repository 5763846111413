import { Suspense } from 'react'
import type { MenuProps } from 'antd'
import { Layout, Menu, Spin } from 'antd'
import './MainLayout.scss'
import { Header } from '../header/Header'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { Footer } from '../footer/Footer'
import {
  ReconciliationOutlined,
  SettingOutlined,
  SyncOutlined,
  TableOutlined,
  UnorderedListOutlined,
  UploadOutlined
} from '@ant-design/icons'
import { RoleRights } from '../../provider/roles'
import { hasRightAssigned } from '../../features/order/orderAccess'
import { BusinessUnit, ProductionSite } from '../../features/order/order'
import { useRoleContext } from '../../provider/RoleProvider'

const { Content, Sider } = Layout

type MenuItem = Required<MenuProps>['items'][number]

export const MainLayout = () => {
  const location = useLocation()

  const clearStorageAndRefresh = () => {
    localStorage.clear()
    window.location.reload()
  }

  const navigate = useNavigate()
  const { selectedSite } = useRoleContext()
  const items: MenuItem[] = [
    {
      key: '/',
      label: 'Orders',
      icon: <TableOutlined/>,
      onClick: () => navigate('/')
    },
    {
      key: '/projects',
      label: 'Projects',
      icon: <ReconciliationOutlined />,
      onClick: () => navigate('/projects')
    },
    {
      key: '/tasks',
      label: 'Tasks',
      icon: <UnorderedListOutlined/>,
      disabled: !hasRightAssigned([BusinessUnit.NGS], RoleRights.READ),
      onClick: () => navigate('/tasks')
    },
    {
      key: '/relogin',
      label: 'Re-Login',
      icon: <SyncOutlined/>,
      onClick: () => clearStorageAndRefresh()
    },
    {
      key: '/settings',
      label: 'Settings',
      icon: <SettingOutlined/>,
      disabled: !hasRightAssigned([BusinessUnit.NGS], RoleRights.ADMIN),
      children: [
        {
          key: '/settings/kit-components',
          label: 'Kit Components',
          disabled: selectedSite !== ProductionSite.EBE,
          icon: <UploadOutlined/>,
          onClick: () => navigate('/settings/kit-components')
        },
        {
          key: '/settings/order-automation',
          label: 'Order Automation',
          disabled: selectedSite !== ProductionSite.EBE,
          icon: <UploadOutlined/>,
          onClick: () => navigate('/settings/order-automation')
        },
        {
          key: '/settings/holidays',
          label: 'Holiday Management',
          icon: <UploadOutlined />,
          onClick: () => navigate('/settings/holidays')
        }
      ].filter(item => !item.disabled)
    }
  ].filter(item => !item.disabled)

  const splitIntoKeys = (input: string[]) => {
    const result = []
    let cumulation = ''
    for (const value of input) {
      cumulation += '/' + value
      result.push(cumulation)
    }
    return result
  }

  //splitIntoKeys('/settings/kit-components') returns ['/', '/settings', '/settings/kit-components'], but we don't need the first slash element, as it will mark incorrect item as selected
  const selectedKeys = splitIntoKeys(location.pathname.split('/').slice(1))

  return (
    <Layout className="app-layout">
      <Header/>
      <Layout>
        <Sider theme="light" breakpoint={'xxl'} collapsible>
          <Menu theme="light" defaultSelectedKeys={selectedKeys} defaultOpenKeys={selectedKeys} mode="inline"
            items={items}/>
        </Sider>
        <Layout>
          <Content className="content-layout">
            <Suspense fallback={<Spin/>}>
              <div className="outlet">
                <Outlet/>
              </div>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
      <Footer/>
    </Layout>
  )
}

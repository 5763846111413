import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { accessToken } from './microsoftToken'
import { notifyError } from '../components/notification/Notification'
import { notification } from 'antd'
import { CustomHttpHeaders } from '../features/order/order'

export const axios = Axios.create({})
const KEY = 'selectedSite'

axios.interceptors.request.use(async (config) => {
  config.headers = config.headers ?? {}
  const token = await accessToken()
  config.headers.Authorization = `Bearer ${token}`
  config.headers[CustomHttpHeaders.SELECTED_SITE] = window.localStorage.getItem(KEY)
  return config
})

axios.interceptors.response.use(
  response => response,
  error => {
    const message = error.response?.data?.message || error.message
    console.error(`Request to '${error.request?.responseURL}' failed with an error: ${message}`)
    return Promise.reject(error)
  }
)

function isNotFoundErrorWithoutReason(errorCode: number, error: any) {
  return errorCode == 404 && !error.response.data.message
}

export const handleError = async (error: any, notFoundErrorMessage?: string) => {
  const errorCode: number = error?.response?.status
  if (errorCode == 403) {
    window.location.replace('/forbidden')
    return Promise.reject(new Error(`Request ${error.request?.responseURL} forbidden`))
  } else if(isNotFoundErrorWithoutReason(errorCode, error)) {
    notification.error({
      message: 'Not found',
      description: notFoundErrorMessage
    })
    return Promise.reject(new Error(`Resource not found for ${error.request?.responseURL} request`))
  } else if (errorCode == 500 || errorCode == 400 || errorCode == 404) {
    return notifyError(error.response.data)
      .then(() => {
        Promise.reject(new Error(`Request ${error.request?.responseURL} failed`))
        throw error
      })
  } else if (errorCode == 409 || errorCode == 418) {
    throw error
  } else {
    throw new Error(error)
  }
}

export const get = async <T>(url: string, config?: AxiosRequestConfig) => {
  return await axios.get<T>(url, config)
    .then(res => res.data)
    .catch(handleError)
}
export const del = async <T>(url: string, config?: AxiosRequestConfig) => {
  return await axios.delete<T>(url, config)
    .then(res => res.data)
    .catch(handleError)
}
export const post = async <T>(url: string, payload?: T, config?: AxiosRequestConfig) => {
  return await axios.post<T>(url, payload, config)
    .then(res => res.data)
    .catch(handleError)
}

export const put = async <T>(url: string, payload?: T, config?: AxiosRequestConfig) => {
  return await axios.put<T>(url, payload, config)
    .then(res => res.data)
    .catch(handleError)
}

const download = async (response: AxiosResponse<any, any>) => {
  const href = URL.createObjectURL(response.data)
  const link = document.createElement('a')

  const contentDisposition = response.headers['content-disposition']
    ?.split(';')
    ?.find((n: string) => n.includes('filename='))
    ?.replace('filename=', '')
    .trim()

  if (!contentDisposition) {
    throw new Error('"content-disposition" header is missing from the response')
  }

  link.href = href
  link.setAttribute('download', decodeURIComponent(contentDisposition))
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

export const postDownload = async (url: string, payload: any, notFoundErrorMessage?: string, config?: AxiosRequestConfig) => {
  return axios.post(url, payload, { responseType: 'blob', ...config })
    .then(download)
    .catch(error => handleError(error, notFoundErrorMessage))
}

export const getDownload = async (url: string, notFoundErrorMessage?: string, config?: AxiosRequestConfig) => {
  return axios.get(url, { responseType: 'blob', ...config })
    .then(download)
    .catch(error => handleError(error, notFoundErrorMessage))
}

import { Col, Dropdown, Layout, Row, theme } from 'antd'
import { useNavigate } from 'react-router'
import './Header.scss'
import logo from '../../assets/eurofins.png'
import { useRoleContext } from '../../provider/RoleProvider'
import { MenuProps } from 'antd/es/menu'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

const { Header: AntdHeader } = Layout
const { useToken } = theme

export const Header = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { token: globalTheme } = useToken()
  const queryClient = useQueryClient()
  const { sites, selectedSite, setSelectedSite } = useRoleContext()
  const siteItems: MenuProps = {
    items: sites.map(site => ({
      key: site,
      label: t(`sites.${site}`),
      onClick: () => {
        setSelectedSite(site)
        queryClient.clear()
      }
    }))
  }
  return (
    <AntdHeader style={{ backgroundColor: globalTheme.colorPrimary }}>
      <div className="logo" onClick={() => navigate('/')} aria-hidden="true">
        <img src={logo} className="logo-image grayscale" alt="Eurofins Logo"/>
        <div>Mint</div>
      </div>
      <div>
        <Row className="lab-selection-row">
          <Col className="lab-label">Site: </Col>
          <Col>
            <Dropdown
              menu={siteItems}
              trigger={['click']}
              disabled={sites.length <= 1}>
              <div className="lab-selection">{t(`sites.${selectedSite}`)} {sites.length > 1 && <DownOutlined/>}</div>
            </Dropdown></Col>
        </Row>
      </div>
    </AntdHeader>
  )
}
